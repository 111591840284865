import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ForecastDropdown from './ForecastDropdown';
import CustomerDropdown from './CustomerDropdown';
import ForecastMonthDropdown from './ForecastMonthDropdown';
import SkuBaseForecastTable from './SkuBaseForecastTable';
import Box from "@mui/material/Box";
import PromotionsTable from "./PromotionsTable";

const CustomerForecast = () => {
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodIndex, setSelectedForecastPeriodIndex] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [startPeriod, setStartPeriod] = useState(1);
    const [hasSkuBaseForecasts, setHasSkuBaseForecasts] = useState(false);
    const [refreshSkuBaseForecasts, setRefreshSkuBaseForecasts] = useState(false);
    const [promotionProductsData, setPromotionProductsData] = useState(null);

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem('selectedForecast'));
        const storedForecastMonth = localStorage.getItem('selectedForecastMonth');

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastMonth) {
            setSelectedForecastPeriodIndex(storedForecastMonth);
        }
    }, []);

    const location = useLocation();
    const isLocked = location.state?.isLocked || false;

    const handleForecastSelected = (id, period) => {
        localStorage.setItem('selectedForecast', JSON.stringify({ forecastId: id, startPeriod: period }));
        setSelectedForecastId(id);
        if (period !== null) {
            setStartPeriod(period);
        }
    };

    const handleForecastMonthSelected = async (forecastMonth) => {
        localStorage.setItem('selectedForecastMonth', forecastMonth);
        setSelectedForecastPeriodIndex(forecastMonth);
    };

    const handleCustomerSelected = (selection) => {
        if (typeof selection === 'object' && selection !== null) {
            if (selection.type === 'customer') {
                setSelectedCustomerId(selection.id);
            } else {
                setSelectedCustomerId(null);
            }
        } else {
            setSelectedCustomerId(selection);
        }
    };

    const handleForecastsChange = (forecasts) => {
        setHasSkuBaseForecasts(forecasts && forecasts.length > 0);
    };

    const handlePromotionProductsDataChange = (data) => {
        setPromotionProductsData(data);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pb: 2 }}>
                <ForecastDropdown
                    selectedForecastId={selectedForecastId}
                    onForecastSelected={handleForecastSelected}
                />
                <ForecastMonthDropdown
                    selectedForecastMonth={selectedForecastPeriodIndex}
                    onForecastMonthSelected={handleForecastMonthSelected}
                    startMonth={startPeriod}
                />
                <CustomerDropdown onCustomerSelected={handleCustomerSelected} />
            </Box>
            {selectedForecastId && selectedForecastPeriodIndex !== null && selectedForecastPeriodIndex > -1 && selectedCustomerId && (
                <Box>
                    <Box sx={{ display: 'flex-end', gap: 2, pb: 2 }}>
                        <SkuBaseForecastTable
                            forecastId={selectedForecastId}
                            periodIndex={selectedForecastPeriodIndex}
                            customerId={selectedCustomerId}
                            isLocked={isLocked}
                            onForecastsChange={handleForecastsChange}
                            refresh={refreshSkuBaseForecasts}
                            onRefreshed={() => setRefreshSkuBaseForecasts(false)}
                            promotionProductsData={promotionProductsData}
                        />
                    </Box>
                    {hasSkuBaseForecasts && (
                        <Box sx={{ display: 'flex-end', gap: 2, pb: 2 }}>
                            <PromotionsTable
                                forecastId={selectedForecastId}
                                periodIndex={selectedForecastPeriodIndex}
                                customerId={selectedCustomerId}
                                onRefreshSkuBaseForecasts={() => setRefreshSkuBaseForecasts(true)}
                                onPromotionProductsDataChange={handlePromotionProductsDataChange}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default CustomerForecast;