import React, { useState, useEffect } from 'react';
import OutputsSummaryTable from './OutputsSummaryTable';
import { FormControl, MenuItem, Select } from "@mui/material";
import ForecastDropdown from "./ForecastDropdown";
import ForecastMonthDropdown from "./ForecastMonthDropdown";
import Box from "@mui/material/Box";
import ForecastProductDropdown from "./ForecastProductDropdown";
import axiosInstance from "../AxiosInstance";

const ForecastOutputsSummary = () => {
    const [view, setView] = useState('units');
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastMonth, setSelectedForecastMonth] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [startPeriod, setStartPeriod] = useState(1);
    const [summaryData, setSummaryData] = useState({ channels: [] });

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem('selectedForecast'));
        const storedForecastMonth = localStorage.getItem('selectedForecastMonth');

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastMonth) {
            setSelectedForecastMonth(storedForecastMonth);
        }
    }, []);

    useEffect(() => {
        if (selectedForecastId && selectedForecastMonth !== null) {
            const fetchAccountSummary = async () => {
                try {
                    const params = { periodIndex: selectedForecastMonth };
                    if (selectedProductId !== null) {
                        params.productId = selectedProductId;
                    }
                    const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-summary`, { params });
                    setSummaryData(response.data);
                } catch (error) {
                    console.error("Failed to fetch account summary:", error);
                    setSummaryData({ channels: [] });
                }
            };

            fetchAccountSummary();
        }
    }, [selectedForecastId, selectedForecastMonth, selectedProductId]);

    const handleForecastSelected = (id, period) => {
        localStorage.setItem('selectedForecast', JSON.stringify({ forecastId: id, startPeriod: period }));
        setSelectedForecastId(id);
        if (period !== null) {
            setStartPeriod(period);
        }
    };

    const handleForecastMonthSelected = async (forecastMonth) => {
        localStorage.setItem('selectedForecastMonth', forecastMonth);
        setSelectedForecastMonth(forecastMonth);
    };

    const handleProductSelected = ({ productId }) => {
        setSelectedProductId(productId);
    };

    const handleChangeView = (event) => {
        setView(event.target.value);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pb: 2 }}>
                <ForecastDropdown
                    selectedForecastId={selectedForecastId}
                    onForecastSelected={handleForecastSelected}
                />
                <ForecastMonthDropdown
                    selectedForecastMonth={selectedForecastMonth}
                    onForecastMonthSelected={handleForecastMonthSelected}
                    startMonth={startPeriod} // Use the dynamic startPeriod
                />
                <ForecastProductDropdown
                    forecastId={selectedForecastId}
                    periodIndex={selectedForecastMonth}
                    onProductSelected={handleProductSelected}
                />
            </Box>
            {selectedForecastId && selectedForecastMonth !== null && selectedForecastMonth > -1 && (
                <Box>
                    <FormControl sx={{ m: 1, width: 200 }}>
                        <Select onChange={handleChangeView} value={view}>
                            <MenuItem value="units">Units</MenuItem>
                            <MenuItem value="grossRev">Gross Revenue</MenuItem>
                            <MenuItem value="netRev">Net Revenue</MenuItem>
                            <MenuItem value="triggers">Triggers</MenuItem>
                            <MenuItem value="fixedSpend">Fixed Spend</MenuItem>
                            <MenuItem value="grossProfit">Gross Profit</MenuItem>
                            <MenuItem value="grossProfitPercent">Gross Profit Percent</MenuItem>
                        </Select>
                    </FormControl>
                    <OutputsSummaryTable summaryData={summaryData} view={view} startMonth={startPeriod} />
                </Box>
            )}
        </div>
    );
};

export default ForecastOutputsSummary;
