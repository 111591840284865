import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";

const ForecastProductMultiSelectDropdown = ({ forecastId, customerId, periodIndex, onProductsSelected, productErrorText }) => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        if (periodIndex !== null) {
            fetchProducts();
        }
    }, [forecastId, customerId, periodIndex]);

    useEffect(() => {
        setHelperText(productErrorText);
    }, [productErrorText]);

    const fetchProducts = async () => {
        try {
            const url = `/forecasts/${forecastId}/products${customerId ? `?customerId=${customerId}&periodIndex=${periodIndex}` : `?periodIndex=${periodIndex}`}`;
            const response = await axiosInstance.get(url);
            setProducts(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductChange = (event, values) => {
        setSelectedProducts(values);
        const selectedProductDetails = values.map(value => ({
            skuBaseForecastId: value.skuBaseForecastId,
            productId: value.productId
        }));
        onProductsSelected(selectedProductDetails);
    };

    return (
        <div>
            <Autocomplete
                multiple
                id="forecast-product-dropdown"
                options={products}
                getOptionLabel={(product) => `${product.skuDescription} - ${product.skuNumber}`}
                sx={{ width: 280 }}
                value={selectedProducts}
                onChange={handleProductChange}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.skuNumber === value.skuNumber}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="SKU"
                        error={Boolean(helperText)}
                        helperText={helperText}
                    />
                )}
            />
        </div>
    );
};

export default ForecastProductMultiSelectDropdown;
