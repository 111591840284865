import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Typography,
} from "@mui/material";
import Draggable from "react-draggable";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ForecastProductMultiSelectDropdown from "./ForecastProductMultiSelectDropdown";
import TextField from "@mui/material/TextField";
import convertDateStringToDate, {formatDate} from "../Utilities/dateUtility";
import {
    isGreaterOrEqualToZero,
    isGreaterThanZero,
    isMoneyGreaterOrEqualToZero,
    isMoneyGreaterThanZero,
    validateString
} from '../Utilities/validation';
import axiosInstance from "../AxiosInstance";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function PromoEditToolbar(props) {
    const {
        title,
        setRows,
        setRowModesModel,
        selectedPromotion,
        setSnackbar,
        handleOpenConfirmationDialog,
    } = props;

    const handleClick = () => {
        const id = -1;

        setRows((oldRows) => {
            const hasNewRow = oldRows.some(row => row.isNew);
            if (hasNewRow) {
                return oldRows;
            }
            const newRow = {
                id,
                promotionTypeId: 1,
                name: '',
                startDate: '',
                endDate: '',
                buyInStart: '',
                buyInEnd: '',
                fixedSpend: 0,
                featureId: 1,
                stores: 0,
                isNew: true,
                canDelete: true
            };

            return [...oldRows, newRow];
        });

        setRowModesModel((oldModel) => ({
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
            ...oldModel,
        }));
    };

    const handleDeleteClick = () => {
        if (!selectedPromotion || !selectedPromotion.id) {
            console.error("No promotion selected for deletion.");
            setSnackbar({children: "No promotion selected", severity: "error"});
            return;
        }
        handleOpenConfirmationDialog('promotion');
    };

    return (
        <GridToolbarContainer sx={{justifyContent: 'space-between', width: '100%'}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="h6" sx={{mr: 2, pl: 1}}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IconButton aria-label="create" onClick={handleClick}>
                    <AddIcon/>
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDeleteClick}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
        </GridToolbarContainer>
    );
}

export default function PromotionsTable({
                                            forecastId,
                                            periodIndex,
                                            customerId,
                                            onRefreshSkuBaseForecasts,
                                            onPromotionProductsDataChange
                                        }) {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedPromotionId, setSelectedPromotionId] = useState(null);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [snackbar, setSnackbar] = useState(null);
    const [features, setFeatures] = useState([]);
    const [promotionTypes, setPromotionTypes] = useState([]);
    const [buyInDateOptions, setBuyInDateOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        recommendedRetailPrice: 0,
        unitFunding: 0,
        skuBaseForecastId: []
    });
    const [skuBaseForecastErrorText, setSkuBaseForecastErrorText] = useState('');
    const [recommendedRetailPriceErrorText, setRecommendedRetailPriceErrorText] = useState('');
    const [unitFundingErrorText, setUnitFundingErrorText] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedPromotionProductId, setSelectedPromotionProductId] = useState(null);
    const [selectedPromotionProduct, setSelectedPromotionProduct] = useState(null);
    const [confirmingDeletionType, setConfirmingDeletionType] = useState(null);
    const [editedPromotionProductRows, setEditedPromotionProductRows] = useState({});

    const handleOpenConfirmationDialog = (type) => {
        setConfirmingDeletionType(type);
        setOpenConfirmationDialog(true);
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmingDeletionType(null);
        setOpenConfirmationDialog(false);
    };

    const handleConfirmDeletePromotion = async () => {
        try {
            const response = await axiosInstance.delete(`/forecasts/${forecastId}/promotions/${selectedPromotion.id}?periodIndex=${periodIndex}`);

            if (response.status === 204 || response.status === 200) {
                setRows(rows.filter(row => row.id !== selectedPromotion.id));
            }

            if (response.status === 204) {
                setSnackbar({children: "Promotion successfully deleted", severity: "success"});
            } else if (response.status === 200) {
                setSnackbar({children: "Promotion successfully deactivated", severity: "success"});
            }
        } catch (error) {
            console.error(`Error deleting the promotion: ${error}`);
            setSnackbar({
                children: `Error deleting the promotion: ${error.response?.data || error.message}`,
                severity: "error"
            });
        } finally {
            setSelectedPromotion(null);
            setSelectedPromotionId(null);
        }
        handleCloseConfirmationDialog();
    };

    const handleConfirmDeletePromotionProduct = async () => {
        if (!selectedPromotionProduct) {
            setSnackbar({children: "No promotion product selected", severity: "error"});
            return;
        }

        try {
            const response = await axiosInstance.delete(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts/${selectedPromotionProductId}?periodIndex=${periodIndex}`);

            if (response.status === 204 || response.status === 200) {
                const updatedPromotionProducts = selectedPromotion.promotionProducts.filter(pp => pp.promotionProductId !== selectedPromotionProductId);
                setSelectedPromotion({...selectedPromotion, promotionProducts: updatedPromotionProducts});
            }

            if (response.status === 204) {
                setSnackbar({children: "Promotion product successfully deleted", severity: "success"});
            } else if (response.status === 200) {
                setSnackbar({children: "Promotion product successfully deactivated", severity: "success"});
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    setSnackbar({
                        children: `Conflict: ${error.response.data.message}`,
                        severity: "warning"
                    });
                } else {
                    setSnackbar({
                        children: `Error deleting the promotion product: ${error.response.data || error.message}`,
                        severity: "error"
                    });
                }
            } else {
                setSnackbar({
                    children: `Error deleting the promotion product: ${error.message}`,
                    severity: "error"
                });
            }
        } finally {
            setSelectedPromotionProduct(null);
            setSelectedPromotionProductId(null);
            handleCloseConfirmationDialog();
        }
    };


    useEffect(() => {
        getPromotions();
    }, [forecastId, periodIndex, customerId]);

    useEffect(() => {
        getBuyInDateOptions();
    }, [forecastId]);

    useEffect(() => {
        if (selectedPromotionId) {
            const updatedSelectedPromotion = rows.find(p => p.id === selectedPromotionId);
            setSelectedPromotion(updatedSelectedPromotion);
        }
    }, [rows, selectedPromotionId]);

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const response = await axiosInstance.get('/features');
                setFeatures(response.data);
            } catch (error) {
                console.error('Error fetching features:', error);
            }
        };

        fetchFeatures();
    }, []);

    useEffect(() => {
        const fetchPromoTypes = async () => {
            try {
                const response = await axiosInstance.get('/promotionTypes');
                setPromotionTypes(response.data);
            } catch (error) {
                console.error('Error fetching promotion types:', error);
            }
        };

        fetchPromoTypes();
    }, []);

    const getPromotions = async () => {
        try {
            const response = await axiosInstance.get(`/forecasts/${forecastId}/promotions/${customerId}?periodIndex=${periodIndex}`);
            const promotions = response.data;
            setRows(promotions);
        } catch (error) {
            console.log(error);
        }
    };

    const getBuyInDateOptions = async () => {
        try {
            const response = await axiosInstance.get(`/forecasts/${forecastId}/promotions/buyInDateOptions`);
            const buyInDateOptions = response.data;
            setBuyInDateOptions(buyInDateOptions);
        } catch (error) {
            console.log(error);
        }
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }

        if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
            const id = params.id;
            const editedRow = rows.find((row) => row.id === id);
            if (editedRow && editedRow.id === -1) {
                setRows(rows.filter((row) => row.id !== -1));
            }
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const parseDate = (dateStr) => {
        if (!dateStr) return null;
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null;

        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed
        const year = parseInt(parts[2], 10);

        const date = new Date(year, month, day);
        return isNaN(date.getTime()) ? null : date;
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = {
            ...newRow,
            periodIndex: periodIndex,
            customerId: customerId,
            isNew: false
        };

        if (updatedRow.promotionTypeId === 2) {
            updatedRow.featureId = null;
            updatedRow.stores = null;
        }

        if (!isGreaterThanZero(newRow.promotionTypeId)) {
            throw new Error('Please select a valid promotion type');
        }

        if (!validateString(newRow.name)) {
            throw new Error('Please enter a valid value for Promotion Name');
        }

        const startDate = new Date(newRow.startDate);
        const endDate = new Date(newRow.endDate);

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            throw new Error('Please enter valid start and end dates');
        }
        if (endDate < startDate) {
            throw new Error('End date must be greater than or equal to start date');
        }

        const buyInStart = parseDate(newRow.buyInStart);
        const buyInEnd = parseDate(newRow.buyInEnd);

        if (!buyInStart || !buyInEnd) {
            throw new Error('Please enter valid Buy In Start and End dates');
        }
        if (buyInEnd < buyInStart) {
            throw new Error('Buy In End date must be greater than or equal to Buy In Start date');
        }

        if (updatedRow.promotionTypeId !== 2 && !isGreaterThanZero(newRow.featureId)) {
            throw new Error('Please select a valid feature');
        }

        if (!isMoneyGreaterOrEqualToZero(newRow.fixedSpend)) {
            throw new Error('Please enter a valid value for Fixed Spend');
        }

        if (updatedRow.promotionTypeId !== 2 && !isGreaterOrEqualToZero(newRow.stores)) {
            throw new Error('Please enter a valid value for Number of Stores');
        }

        if (isGreaterThanZero(newRow.id)) {
            try {
                await axiosInstance.put(`/forecasts/${forecastId}/promotions`, updatedRow);
                setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                setSnackbar({children: 'Promotion successfully updated', severity: 'success'});
                //todo: we call getPromotions as this populates both the Promo and PromoProducts grid. Could this be split if it's inefficient?
                await getPromotions();
            } catch (error) {
                console.error('Error updating the promotion:', error);
                let errorMessage = error.response.data;
                throw new Error(errorMessage);
            }
        } else {
            try {
                const response = await axiosInstance.post(`/forecasts/${forecastId}/promotions`, updatedRow);
                const createdRow = response.data;
                setRows((prevRows) => {
                    return prevRows.map(row => row.id === -1 ? createdRow : row);
                });
                setSnackbar({children: 'Promotion successfully created', severity: 'success'});
            } catch (error) {
                console.error('Error creating the promotion:', error);
                throw new Error('Error creating the promotion.');
            }
        }

        onRefreshSkuBaseForecasts();

        return updatedRow;
    };

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({children: error.message, severity: 'error'});
    }, []);

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handlePromotionRowClick = (params) => {
        if (params.row.isNew) {
            setSelectedPromotion(null);
            setSelectedPromotionId(null);
            return;
        }

        const promotionProductsData = params.row.promotionProducts.map(product => ({
            skuBaseForecastId: product.skuBaseForecastId,
            weeklyDistributionRates: product.weeklyDistributionRates.map(rate => rate.week)
        }));

        onPromotionProductsDataChange(promotionProductsData);

        setSelectedPromotionId(params.id);
        setSelectedPromotion(params.row);
    };

    const isCellEditable = (params) => {
        return params.field === 'promotionTypeId' ? params.row.isNew : true;
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 50},
        {
            field: 'promotionTypeId',
            headerName: 'Type',
            width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: promotionTypes.map((type) => ({
                value: type.promotionTypeId,
                label: type.name
            })),
            valueFormatter: (value) => {
                const type = promotionTypes.find(f => f.promotionTypeId === value);
                return type ? type.name : '';
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'startDate',
            headerName: 'Promo Start',
            type: 'date',
            width: 100,
            editable: true,
            valueFormatter: (value) => formatDate(value)
        },
        {
            field: 'endDate',
            headerName: 'Promo End',
            type: 'date',
            width: 100,
            editable: true,
            valueFormatter: (value) => formatDate(value)
        },
        {
            field: 'buyInStart',
            headerName: 'Buy In Start',
            type: 'singleSelect',
            width: 140,
            editable: true,
            valueOptions: buyInDateOptions,
            valueFormatter: (value) => value,
            valueGetter: (value, row) => row.buyInStart
        },
        {
            field: 'buyInEnd',
            headerName: 'Buy In End',
            type: 'singleSelect',
            width: 140,
            editable: true,
            valueOptions: buyInDateOptions,
            valueFormatter: (value) => value,
            valueGetter: (value, row) => row.buyInEnd
        },
        {
            field: 'fixedSpend',
            headerName: 'Fixed Spend',
            type: 'number',
            width: 100,
            editable: true,
            valueFormatter: (value) => {
                return '£' + parseFloat(value).toFixed(2);
            },
        },
        {
            field: 'featureId',
            headerName: 'Feature',
            width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({row}) => features.map((feature) => ({
                value: feature.featureId,
                label: feature.name
            })),
            valueFormatter: (value) => {
                if (value === null) return '';
                const feature = features.find(f => f.featureId === value);
                return feature ? feature.name : '';
            },
            preProcessEditCellProps: (params) => {
                const hasError = params.row.promotionTypeId === 2;
                return {...params.props, disabled: hasError, style: hasError ? {backgroundColor: '#e0e0e0'} : {}};
            },
            renderEditCell: (params) => (
                <Select
                    value={params.value ?? ''}
                    onChange={(event) => params.api.setEditCellValue({
                        id: params.id,
                        field: 'featureId',
                        value: event.target.value
                    })}
                    disabled={params.row.promotionTypeId === 2}
                    style={params.row.promotionTypeId === 2 ? {backgroundColor: '#e0e0e0'} : {}}
                >
                    {features.map((feature) => (
                        <MenuItem key={feature.featureId} value={feature.featureId}>
                            {feature.name}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: 'stores',
            headerName: 'Stores',
            type: 'number',
            width: 80,
            editable: true,
            preProcessEditCellProps: (params) => {
                const hasError = params.row.promotionTypeId === 2;
                return {...params.props, disabled: hasError, style: hasError ? {backgroundColor: '#e0e0e0'} : {}};
            },
            renderEditCell: (params) => (
                <TextField
                    value={params.value ?? ''}
                    onChange={(event) => params.api.setEditCellValue({
                        id: params.id,
                        field: 'stores',
                        value: event.target.value
                    })}
                    disabled={params.row.promotionTypeId === 2}
                    style={params.row.promotionTypeId === 2 ? {backgroundColor: '#e0e0e0'} : {}}
                />
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
        {
            field: 'totalUnits',
            headerName: 'Total Units',
            type: 'number',
            width: 80,
            editable: false,
            cellClassName: (params) => 'nonEditableCell',
        },
        {
            field: 'netRevenue',
            headerName: 'Net Rev',
            type: 'number',
            width: 100,
            editable: false,
            valueFormatter: (value) => value !== null && value !== undefined ? `£${value.toFixed(2)}` : '',
            cellClassName: (params) => 'nonEditableCell',
        },
    ];

    const promotionProductColumns = useMemo(() => {
        if (!selectedPromotion || !selectedPromotion.promotionProducts) {
            return [];
        }

        const buyInEndDate = convertDateStringToDate(selectedPromotion?.buyInEnd);

        if (buyInEndDate) {
            buyInEndDate.setHours(0, 0, 0, 0);
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isReadOnly = buyInEndDate ? today > buyInEndDate : false;

        const staticColumns = [
            {field: 'promotionProductId', headerName: 'ID', width: 100},
            {field: 'skuBaseForecastId', headerName: 'SBF ID', width: 100},
            {field: 'skuDescription', headerName: 'SKU Description', width: 200},
            {
                field: 'recommendedRetailPrice',
                headerName: 'RRP (£)',
                type: 'number',
                width: 100,
                editable: !isReadOnly,
                valueFormatter: (value) => `£${value.toFixed(2)}`,
                cellClassName: (params) => isReadOnly ? 'nonEditableCell' : '',
            },
            {
                field: 'unitFunding',
                headerName: 'Unit Funding (£)',
                type: 'number',
                width: 100,
                editable: !isReadOnly,
                valueFormatter: (value) => `£${value.toFixed(2)}`,
                cellClassName: (params) => isReadOnly ? 'nonEditableCell' : '',
            }
        ];

        let promotionProducts = selectedPromotion?.promotionProducts;

        const dynamicColumns = promotionProducts.length && promotionProducts[0]?.weeklyDistributionRates
            ? promotionProducts[0].weeklyDistributionRates.map(wdr => {
                const [day, month, year] = wdr.week.split('-').map(Number);
                const columnDate = new Date(year, month - 1, day);
                columnDate.setHours(0, 0, 0, 0);

                let isEditable = columnDate >= today;

                return {
                    field: wdr.week,
                    headerName: wdr.week,
                    type: 'number',
                    width: 100,
                    editable: isEditable,
                    valueGetter: (value, row) => row.weeklyDistributionRates.find(w => w.week === wdr.week)?.actualDistributionRate,
                    cellClassName: (params) => isEditable ? '' : 'nonEditableCell',
                };
            })
            : [];

        return [...staticColumns, ...dynamicColumns];
    }, [selectedPromotion]);

    const handleCreatePromotionProductClick = () => {
        setOpen(true);
        setFormData({
            recommendedRetailPrice: 0,
            unitFunding: 0,
            skuBaseForecastId: []
        });
        setSkuBaseForecastErrorText('');
        setRecommendedRetailPriceErrorText('');
        setUnitFundingErrorText('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProductsSelected = (products) => {
        setFormData((prevState) => ({
            ...prevState,
            skuBaseForecastId: products.map(product => product.skuBaseForecastId),
        }));
    };

    const handleTextFieldChange = (event) => {
        const {name, value} = event.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreatePromoProduct = async (data) => {
        const isValidRecommendedRetailPrice = isMoneyGreaterThanZero(data.recommendedRetailPrice);
        const isValidUnitFunding = isMoneyGreaterThanZero(data.unitFunding);

        const isValidSkuBaseForecastId = data.skuBaseForecastId.length > 0;

        if (!isValidRecommendedRetailPrice || !isValidUnitFunding || !isValidSkuBaseForecastId) {
            setRecommendedRetailPriceErrorText(isValidRecommendedRetailPrice ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setUnitFundingErrorText(isValidUnitFunding ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setSkuBaseForecastErrorText(isValidSkuBaseForecastId ? '' : 'Please select a SKU');
            return;
        }

        setRecommendedRetailPriceErrorText('');
        setUnitFundingErrorText('');
        setSkuBaseForecastErrorText('');

        try {
            data.customerId = customerId;
            data.periodIndex = periodIndex;

            const requests = data.skuBaseForecastId.map(skuBaseForecastId => {
                const promotionData = { ...data, skuBaseForecastId };
                return axiosInstance.post(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts`, promotionData);
            });

            const results = await Promise.allSettled(requests);

            const successful = [];
            const failed = [];

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    successful.push(data.skuBaseForecastId[index]);
                } else {
                    failed.push(data.skuBaseForecastId[index]);
                }
            });

            if (successful.length > 0) {
                handleClose();
                await getPromotions();
            }

            if (failed.length > 0) {
                const failedSkuBaseForecastNames = failed.map(id => `SKU Base Forecast ID: ${id}`).join(', ');

                setSnackbar({
                    children: `Failed to add the following SKUs: ${failedSkuBaseForecastNames}`,
                    severity: 'error',
                });
            } else if (successful.length > 0) {
                setSnackbar({
                    children: 'Promotion products created successfully.',
                    severity: 'success',
                });
            } else {
                setSnackbar({
                    children: 'Error creating promotion products.',
                    severity: 'error',
                });
            }
        } catch (error) {
            console.error('Error creating promotion product:', error);
            let errorMessage = 'Error creating promotion products.';
            if (error.response && error.response.status === 500) {
                errorMessage = error.response.data || 'Server error while creating promotion products.';
            }
            setSnackbar({ children: errorMessage, severity: 'error' });
        }
    };

    const handlePromotionProductRowClick = (params) => {
        setSelectedPromotionProductId(params.id);
        setSelectedPromotionProduct(params.row);
    };

    function handleProcessPromotionProductRowUpdateError(error) {
        setSnackbar({children: "There was an error processing the row update. Please try again.", severity: "error"});
    }

    function moveTopLevelDateKeysToNested(newRow) {
        const weeklyRatesMap = newRow.weeklyDistributionRates.reduce((acc, current) => {
            acc[current.week] = current;
            return acc;
        }, {});

        const datePattern = /^\d{2}-\d{2}-\d{4}$/;

        Object.keys(newRow).forEach(key => {
            if (datePattern.test(key) && weeklyRatesMap[key]) {
                weeklyRatesMap[key].actualDistributionRate = newRow[key];
                delete newRow[key];
            }
        });

        newRow.weeklyDistributionRates = Object.values(weeklyRatesMap);

        return newRow;
    }

    const getChangedWeeklyDistributionRates = (newRates, oldRates) => {
        const changes = [];
        for (const key of Object.keys(newRates)) {
            if (oldRates.hasOwnProperty(key)) {
                const oldValue = oldRates[key].actualDistributionRate;
                const newValue = newRates[key].actualDistributionRate;

                if (!isNaN(oldValue) && !isNaN(newValue) && newValue !== oldValue) {
                    changes.push({week: newRates[key].week, actualDistributionRate: newValue});
                }
            }
        }

        return changes;
    };

    const isValidWeeklyDistributionRates = (weeklyDistributionRates) => {
        return weeklyDistributionRates.every(rateObj => {
            const intValue = parseInt(rateObj.actualDistributionRate, 10);
            return !isNaN(intValue) && intValue >= 0 && intValue === parseFloat(rateObj.actualDistributionRate);
        });
    };

    const processPromotionProductRowUpdates = (newRow, oldRow) => {
        let validationErrors = [];
        let changes = {};

        const copiedNewRow = JSON.parse(JSON.stringify(newRow));
        moveTopLevelDateKeysToNested(copiedNewRow);

        const changedRates = getChangedWeeklyDistributionRates(copiedNewRow.weeklyDistributionRates, oldRow.weeklyDistributionRates);

        if (!isValidWeeklyDistributionRates(changedRates)) {
            validationErrors.push("Please enter a valid value for weekly distribution rate.");
            copiedNewRow.weeklyDistributionRates = oldRow.weeklyDistributionRates;
        } else if (changedRates.length > 0) {
            changes.weeklyDistributionRates = changedRates;
        }

        if (!isMoneyGreaterOrEqualToZero(newRow.unitFunding)) {
            validationErrors.push("Please enter a valid value for unit funding.");
            copiedNewRow.unitFunding = oldRow.unitFunding;
        } else if (newRow.unitFunding !== oldRow.unitFunding) {
            changes.unitFunding = newRow.unitFunding;
        }

        if (!isMoneyGreaterOrEqualToZero(newRow.recommendedRetailPrice)) {
            validationErrors.push("Please enter a valid value for RRP.");
            copiedNewRow.recommendedRetailPrice = oldRow.recommendedRetailPrice;
        } else if (newRow.recommendedRetailPrice !== oldRow.recommendedRetailPrice) {
            changes.recommendedRetailPrice = newRow.recommendedRetailPrice;
        }

        if (validationErrors.length > 0) {
            setSnackbar({children: validationErrors.join(" "), severity: "error"});
            setEditedPromotionProductRows(prevState => {
                const newState = {...prevState};
                delete newState[copiedNewRow.promotionProductId];
                return newState;
            });
            return oldRow;
        } else {
            if (Object.keys(changes).length > 0) {
                setEditedPromotionProductRows(prevState => ({
                    ...prevState,
                    [copiedNewRow.promotionProductId]: {...oldRow, ...changes}
                }));
            }
            return copiedNewRow;
        }
    }

    const transformEditedRowToOverrideRequest = (editedRow, originalRow, forecastMonth) => {
        const promotionDistributionRateOverrides = getChangedWeeklyDistributionRates(
            editedRow.weeklyDistributionRates,
            originalRow.weeklyDistributionRates
        ).map(change => ({
            PromotionProductId: editedRow.promotionProductId,
            WeekStart: change.week,
            PromoDistributionRate: change.actualDistributionRate,
            PeriodIndex: forecastMonth
        }));

        let promotionProductOverrides = null;
        if (editedRow.unitFunding !== originalRow.unitFunding || editedRow.recommendedRetailPrice !== originalRow.recommendedRetailPrice) {
            promotionProductOverrides = {
                PromotionProductId: editedRow.promotionProductId,
                UnitFunding: parseFloat(editedRow.unitFunding || 0),
                RecommendedRetailPrice: parseFloat(editedRow.recommendedRetailPrice || 0),
                PeriodIndex: forecastMonth
            };
        }

        return {
            PromotionDistributionRateOverrides: promotionDistributionRateOverrides,
            PromotionProductOverrides: promotionProductOverrides
        };
    };


    const handleSavePromotionProductClick = async () => {
        try {
            const rowsArray = Object.values(editedPromotionProductRows);

            const transformedRows = rowsArray.map(editedRow => {
                const originalRow = selectedPromotion.promotionProducts.find(row => row.promotionProductId === editedRow.promotionProductId);
                return transformEditedRowToOverrideRequest(editedRow, originalRow, periodIndex);
            });

            const payload = {
                PromotionDistributionRateOverrides: [].concat(...transformedRows.map(row => row.PromotionDistributionRateOverrides)),
                PromotionProductOverrides: transformedRows.map(row => row.PromotionProductOverrides).filter(override => override !== null)
            };

            const response = await axiosInstance.post(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts/bulk`, payload);

            if (response.status === 200) {
                setSnackbar({children: "Changes saved successfully", severity: "success"});
                setEditedPromotionProductRows({});
                await getPromotions();
                onRefreshSkuBaseForecasts();
            } else {
                setSnackbar({children: "Failed to save changes", severity: "error"});
            }
        } catch (error) {
            console.error(error.response.data);
            setSnackbar({children: `Error saving changes: ${error.message}`, severity: "error"});
        }
    }

    const PromoProductEditToolbar = ({title}) => {
        const handleDeletePromotionProduct = () => {
            if (!selectedPromotionProduct) {
                setSnackbar({children: "No promotion product selected", severity: "error"});
                return;
            }
            handleOpenConfirmationDialog('promotionProduct');
        };

        return (
            <GridToolbarContainer sx={{justifyContent: 'space-between', width: '100%'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h6" sx={{mr: 2, pl: 1}}>
                        {title}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton aria-label="create" onClick={handleCreatePromotionProductClick}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton aria-label="save" onClick={handleSavePromotionProductClick}
                                disabled={Object.keys(editedPromotionProductRows).length === 0}>
                        <SaveIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={handleDeletePromotionProduct}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                isCellEditable={isCellEditable}
                slots={{
                    toolbar: PromoEditToolbar,
                }}
                slotProps={{
                    toolbar: {
                        title: "Promos",
                        setRows,
                        setRowModesModel,
                        selectedPromotion,
                        setSnackbar,
                        handleOpenConfirmationDialog
                    }
                }}
                onRowClick={handlePromotionRowClick}
                pageSize={10}
                autoHeight
            />
            {selectedPromotion && selectedPromotion.promotionTypeId === 1 && (
                <Box sx={{height: 'auto', width: '100%', mt: 4}}>
                    <DataGrid
                        getRowId={(row) => row.promotionProductId}
                        rows={selectedPromotion.promotionProducts}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    promotionProductId: false,
                                    skuBaseForecastId: false,
                                },
                            },
                        }}
                        columns={promotionProductColumns}
                        pageSize={5}
                        autoHeight
                        slots={{
                            toolbar: PromoProductEditToolbar,
                        }}
                        slotProps={{
                            toolbar: {title: "Promos - Sku Forecast"},
                        }}
                        onRowClick={handlePromotionProductRowClick}
                        processRowUpdate={processPromotionProductRowUpdates}
                        onProcessRowUpdateError={handleProcessPromotionProductRowUpdateError}
                        editMode="row"
                    />
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Create Promotion Product</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{pb: 2}}>
                                Enter the details for the new Promotion Product.
                            </DialogContentText>
                            <Box sx={{pb: 2}}>
                                <ForecastProductMultiSelectDropdown
                                    forecastId={forecastId}
                                    customerId={customerId}
                                    periodIndex={periodIndex}
                                    onProductsSelected={handleProductsSelected}
                                    productErrorText={skuBaseForecastErrorText}
                                />
                            </Box>
                            <Box sx={{pb: 2}}>
                                <TextField
                                    label="RRP"
                                    name="recommendedRetailPrice"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={formData.recommendedRetailPrice}
                                    error={Boolean(recommendedRetailPriceErrorText)}
                                    helperText={recommendedRetailPriceErrorText}
                                    onChange={handleTextFieldChange}
                                />
                            </Box>
                            <Box sx={{pb: 2}}>
                                <TextField
                                    label="Unit Funding"
                                    name="unitFunding"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={formData.unitFunding}
                                    error={Boolean(unitFundingErrorText)}
                                    helperText={unitFundingErrorText}
                                    onChange={handleTextFieldChange}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={() => handleCreatePromoProduct(formData)}>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmingDeletionType === 'promotion'
                            ? 'Are you sure you want to delete this promotion? This action cannot be undone.'
                            : 'Are you sure you want to remove this SKU from its promotion? This action cannot be undone.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (confirmingDeletionType === 'promotion') {
                                handleConfirmDeletePromotion();
                            } else if (confirmingDeletionType === 'promotionProduct') {
                                handleConfirmDeletePromotionProduct();
                            }
                        }}
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
