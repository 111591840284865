import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";

const ProductDropdown = ({ onProductsSelected, productErrorText, setProducts }) => {
    const [products, setProductsState] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        setHelperText(productErrorText);
    }, [productErrorText]);

    const fetchProducts = async () => {
        try {
            const response = await axiosInstance.get('/products');
            setProductsState(response.data);
            setProducts(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductChange = (event, values) => {
        setSelectedProducts(values);
        const selectedProductIds = values.map(value => value.productId);
        onProductsSelected(selectedProductIds);
    };

    return (
        <div>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={products}
                getOptionLabel={(product) => product.skuDescription + ' - ' + product.skuNumber}
                sx={{ width: 280 }}
                value={selectedProducts}
                onChange={handleProductChange}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="SKU"
                        error={Boolean(helperText)}
                        helperText={helperText}
                    />
                )}
            />
        </div>
    );
};

export default ProductDropdown;
