import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CustomerDropdown = ({ onCustomerSelected, channelSelectable = false }) => {
    const [customerGroups, setCustomerGroups] = useState([]);

    useEffect(() => {
        fetchGroupedCustomers();
    }, []);

    const fetchGroupedCustomers = async () => {
        try {
            const response = await axiosInstance.get('/customers/grouped');
            const optionsWithGroup = response.data.flatMap(group => [
                {
                    channelName: group.channelName,
                    name: group.channelName,
                    isChannel: true,
                    channelId: group.channelId
                },
                ...group.customers.map(customer => ({
                    ...customer,
                    channelName: group.channelName,
                    isChannel: false
                }))
            ]);
            setCustomerGroups(optionsWithGroup);
        } catch (error) {
            console.error('Failed to fetch customers:', error);
        }
    };

    // Custom styles
    const ChannelItem = styled(Typography)(({ theme }) => ({
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        paddingLeft: theme.spacing(1)
    }));

    const CustomerItem = styled(Typography)(({ theme }) => ({
        paddingLeft: theme.spacing(3)
    }));

    return (
        <Autocomplete
            id="grouped-customers-dropdown"
            options={customerGroups}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) => option.isChannel && !channelSelectable}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.isChannel ? (
                        <ChannelItem>{option.name}</ChannelItem>
                    ) : (
                        <CustomerItem>{option.name}</CustomerItem>
                    )}
                </li>
            )}
            renderInput={(params) => <TextField {...params} label="Select a Customer" />}
            onChange={(event, value) => {
                if (value?.isChannel) {
                    onCustomerSelected({ type: 'channel', id: value.channelId });
                } else {
                    onCustomerSelected({ type: 'customer', id: value?.customerId });
                }
            }}
            sx={{ width: 300 }}
        />
    );
};

export default CustomerDropdown;
