import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import { NavMenu } from './components/NavMenu';
import ProtectedRoute from './ProtectedRoute';
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import Home from "./components/Home";
import ProductTable from "./components/ProductTable";
import CustomerTable from "./components/CustomerTable";
import CustomerForecast from "./components/CustomerForecast";
import ForecastSummary from "./components/ForecastSummary";
import ForecastOutputsSummary from "./components/ForecastOutputsSummary";
import ForecastOutputsAccount from "./components/ForecastOutputsAccount";
import './custom.css';
import AdminPage from "./components/AdminPage";
import { AuthProvider } from "./AuthContext";
import ProfilePage from "./components/ProfilePage";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

function App() {
    return (
        <AuthProvider>
            <NavMenu/>
            <Layout>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    {/* Protected Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/products-table" element={<ProductTable />} />
                        <Route path="/customers-table" element={<CustomerTable />} />
                        <Route path="/customer-forecast/:id?" element={<CustomerForecast />} />
                        <Route path="/forecast-summary" element={<ForecastSummary />} />
                        <Route path="/forecast-outputs" element={<ForecastOutputsSummary />} />
                        <Route path="/forecast-outputs-account" element={<ForecastOutputsAccount />} />
                        <Route path="/profile" element={<ProfilePage />} />
                    </Route>
                    {/* Admin Routes */}
                    <Route element={<ProtectedRoute isAdminRequired={true} />}>
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                    </Route>
                </Routes>
            </Layout>
        </AuthProvider>
    );
}

export default App;
