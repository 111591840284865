import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ForecastMonthDropdown = ({ selectedForecastMonth, onForecastMonthSelected, startMonth }) => {
    const defaultMonths = [
        { label: 'January', id: 1 },
        { label: 'February', id: 2 },
        { label: 'March', id: 3 },
        { label: 'April', id: 4 },
        { label: 'May', id: 5 },
        { label: 'June', id: 6 },
        { label: 'July', id: 7 },
        { label: 'August', id: 8 },
        { label: 'September', id: 9 },
        { label: 'October', id: 10 },
        { label: 'November', id: 11 },
        { label: 'December', id: 12 },
    ];

    // Adjust month order based on the `startMonth`
    const rotatedMonths = [
        ...defaultMonths.slice(startMonth - 1),
        ...defaultMonths.slice(0, startMonth - 1)
    ];

    // Add Budget option and assign sequential IDs starting from 1 for months
    const reorderedMonths = [
        { label: 'Budget', id: 0 },
        ...rotatedMonths.map((month, index) => ({ ...month, id: index + 1 }))
    ];

    const selectedMonth = reorderedMonths.find(m => m.id === parseInt(selectedForecastMonth, 10));

    const handleForecastMonthChange = (event, value) => {
        onForecastMonthSelected(value ? value.id : null);
    };

    return (
        <div>
            <Autocomplete
                disablePortal
                id="combo-box-month"
                options={reorderedMonths}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: 300 }}
                value={selectedMonth || null}
                onChange={handleForecastMonthChange}
                renderInput={(params) => <TextField {...params} label="Forecast" />}
            />
        </div>
    );
};

export default ForecastMonthDropdown;
