import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    // Define public routes that don't require authentication
    const publicRoutes = ['/login', '/forgot-password', '/reset-password'];

    useEffect(() => {
        const checkAuthentication = () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    if (decodedToken.exp * 1000 < Date.now()) {
                        // Token expired, clear and redirect if not on a public route
                        localStorage.removeItem('token');
                        setIsAuthenticated(false);
                        setIsAdmin(false);
                        if (!publicRoutes.includes(location.pathname)) {
                            navigate('/login', { replace: true });
                        }
                    } else {
                        setIsAuthenticated(true);
                        setIsAdmin(decodedToken.role === 'Admin');
                    }
                } catch (error) {
                    console.error("Token decoding failed", error);
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                    setIsAdmin(false);
                    if (!publicRoutes.includes(location.pathname)) {
                        navigate('/login', { replace: true });
                    }
                }
            } else {
                // No token, redirect only if not on a public route
                setIsAuthenticated(false);
                setIsAdmin(false);
                if (!publicRoutes.includes(location.pathname)) {
                    navigate('/login', { replace: true });
                }
            }
            setLoading(false);
        };

        checkAuthentication();
    }, [navigate, location.pathname]); // Depend on location.pathname to re-run on route change

    const handleLogin = (email, token) => {
        localStorage.setItem('token', token);
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        setIsAdmin(decodedToken.role === 'Admin');
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setIsAdmin(false);
        navigate('/login', { replace: true });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            isAdmin,
            handleLogin,
            handleLogout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
